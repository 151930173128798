import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function ChriateImage({ image, gImage, ...theRest }) {
  if (!image) return null;

  const img = getImage(image?.localFile);

  if (image.localFile.extension === 'gif' || image.localFile.extension === 'svg') {
    return (
      <div data-gatsby-image-wrapper className={`gatsby-image-wrapper ${image.localFile.extension}`}>
        <img src={image?.mediaItemUrl ? image.mediaItemUrl : image.publicUrl} {...theRest} />
      </div>
    );
  }
  return <GatsbyImage image={gImage ? gImage : img} {...theRest} />;
}