import React, { useId } from 'react';
import { useMouseHandlers } from '../../utils/hooks/useMouseHandlers';
import './Section.scss';

const Section = ({ padding, classes, height, children, disableCustomCursor = true, backgroundColour = '#f2f3f0', location, offset = '50%,50%'}) => {
  const sectionId = useId();
  const mouseHandlers = useMouseHandlers();
  let paddings = '';

  if(padding?.paddingTop) {
    paddings += ` p-lg-top`;
  }

  if(padding?.paddingBottom) {
    paddings += ` p-lg-bottom`;
  }

  if(padding?.paddingTopMobile) {
    paddings += ` p-top`;
  }

  if(padding?.paddingBottomMobile) {
    paddings += ` p-bottom`;
  }

  // Hmmm
  if(location === 'home' && !disableCustomCursor) {
    return (
      <section className={`section ${classes} ${height === 'full' && 'full'} ${paddings}`} data-bg={backgroundColour}>
        <div className="section__block" data-scroll data-scroll-offset={offset} data-scroll-id={'section-' + sectionId}>
          {children}
        </div>
      </section>
   );

  } else if(location === 'home' && disableCustomCursor) {
    return (
      <section className={`section ${classes} default-cursor ${height === 'full' && 'full'} ${paddings}`} {...mouseHandlers}  data-bg={backgroundColour}>
        <div className="section__block" data-scroll data-scroll-offset={offset} data-scroll-id={'section-' + sectionId}>
            {children}
        </div>
      </section>
   );

  } else if(!disableCustomCursor) {
    return (
      <section className={`section ${classes} default-cursor ${height === 'full' && 'full'} ${paddings}`} {...mouseHandlers}  data-scroll-section data-bg={backgroundColour}>
        <div className="section__block" data-scroll data-scroll-offset={offset} data-scroll-id={'section-' + sectionId}>
            {children}
        </div>
      </section>
   );

  } else {
    return (
      <section className={`section ${classes} default-cursor ${height === 'full' && 'full'} ${paddings}`} {...mouseHandlers}  data-scroll-section data-bg={backgroundColour}>
        <div className="section__block" data-scroll data-scroll-offset={offset} data-scroll-id={'section-' + sectionId}>
            {children}
        </div>
      </section>
   );

  }

}

export default Section;