import React from 'react';
import Hero from '../modules/Hero/Hero';
import LinkBlock from '../modules/LinkBlock/LinkBlock';

// Cool dynamic fetch of a component
// import * as sections from "../modules/Sections" need to sort the modules in this format tho..

// {homepage.blocks.map((block) => {
//   const { id, blocktype, ...componentProps } = block
//   const Component = sections[blocktype] || Fallback
//   return <Component key={id} {...componentProps} />
// })}


const Home = ({data}) => {
  // const { modules } = data.wpPage.template.pageModules;

  /* TODO Fetch all of the modules from this folder like above.
  **  Maybe to dynamically get the correct modules we use 'fieldGroupName'
  **  cut off "Template_Modules_Pagemodules_Modules_" and fetch the word after
  ** and use the dynamic component above.
  */

  return (
    <>
      <div className="home">
        <Hero />
        <LinkBlock />
      </div>
    </>
  )
}

export default Home;