import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'gatsby';
import Section from '../../components/Section/Section';
import ContentBlock from '../../components/ContentBlock/ContentBlock';
import { StateContext } from '../../utils/context/stateContext';
import { useMouseHandlers } from '../../utils/hooks/useMouseHandlers';
import './LinkBlock.scss';

gsap.registerPlugin(ScrollTrigger);

const LinkBlock = ({ links, backgroundColour, pageLocation }) => {
  const [currentEl, setCurrentEl] = useState([]);
  const mouseHandlersLeft = useMouseHandlers('left');
  const mouseHandlersRight = useMouseHandlers('right');

  useEffect(() => {
    const nodes = Array.prototype.slice.call(document.querySelector('.link_blocks .link_blocks__row .link_blocks__column').children );

    gsap.to('.link_blocks .link_blocks__column:first-child .content_block', {
      opacity: 1,
      duration: 0
    });

    if(currentEl[0]) {
      gsap.to(currentEl[0], {
        opacity: 1,
        duration: 0.04
      });

      if(nodes.indexOf(currentEl[0]) < 0) {
        // ! Jank
        gsap.to('.hero__heading--link.left span', {
          opacity: 0,
          duration: 0.1,
        });

        gsap.to('.hero__heading--link.right span', {
          opacity: 1,
          duration: 0.1
        });
      } else {
        // ! Jank
        gsap.to('.hero__heading--link.left span', {
          opacity: 1,
          duration: 0.1
        });

        gsap.to('.hero__heading--link.right span', {
          opacity: 0,
          duration: 0.1
        });
      }

    }

    if(currentEl[1]) {
      gsap.to(currentEl[1], {
        opacity: 0,
        duration: 0.04
      });
    }

    ScrollTrigger.matchMedia({
      '(max-width: 1023px)': function() {
        const linkColumns = gsap.utils.toArray('.link_blocks__column');
        linkColumns.forEach((column, index) => {
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: column,
              start: 'top 50%',
              end: 'bottom bottom',
              toggleActions: 'play none resume reset'
            }
          });

          if(index === 0) {
            tl.to('.hero__heading--link.left span', {
              opacity: 1,
              duration: 0.1
            }).to('.hero__heading--link.right span', {
              opacity: 0,
              duration: 0.1
            });

          } else {
            tl.to('.hero__heading--link.left span', {
              opacity: 0,
              duration: 0.1,
            }).to('.hero__heading--link.right span', {
              opacity: 1,
              duration: 0.1
            },0);
          }
        });

      }
    });


  },[currentEl]);

  return (
    <StateContext.Provider value={currentEl}>
      <Section classes="link_blocks direction--multi" height="full" disableCustomCursor={false} data-scroll data-scroll-speed="0" backgroundColour={backgroundColour} location={pageLocation}>
        <div className="link_blocks__row row" >
          {links && links.map(({title, text, text2, link}, index) => {
            if(index === 0) {
              return (
                <Link key={index} to={link.url} className={`link_blocks__column`} {...mouseHandlersLeft}>
                  <ContentBlock index={index} title={title} text1={text} text2={text2} linkUrl={link.url} linkLabel={link.title} linkMobileOnly={true} hasPaddingRight={true} hoverable={true} updateCurrentEl={setCurrentEl}/>
                </Link>
              )
            }
            return (
              <Link key={index} to={link.url} className={`link_blocks__column`} {...mouseHandlersRight}>
                <ContentBlock index={index} title={title} text1={text} text2={text2} linkUrl={link.url} linkLabel={link.title} linkMobileOnly={true} hasPaddingRight={true} hoverable={true} updateCurrentEl={setCurrentEl}/>
              </Link>
            )
          })}
        </div>
      </Section>
    </StateContext.Provider>
   );
}

export default LinkBlock;